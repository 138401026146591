<template>
  <v-container fluid>
    <v-snackbar top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>
    <v-overlay class="loading-center" :value="saving">
      <lottie
        :options="defaultOptions"
        :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>

    <v-row class="pa-0 ma-0">
      <v-col cols="12" sm="11" md="11">
        <h1>Promociones</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="12" sm="12">
        <v-row justify="end">
          <v-btn
            color="primary"
            class="ma-2 white--text"
            @click="newPromo()"
            :loading="loading"
          >
            <v-icon right dark class="mr-3">fas fa-plus</v-icon>
            Crear nueva promoción
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="pa-0 ma-0">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="promos"
          :items-per-page="15"
          :loading="loading"
          :search="$store.state.search"
          :sort-desc="true"
          item-key=".key"
          :single-expand="true"
          :footer-props="{
            itemsPerPageOptions: [10, 25, 50, 100, 250],
          }"
        >
          <template v-slot:loading>
            <div class="ma-5" style="position: relative">
              <div
                style="
                  position: absolute;
                  z-index: 999;
                  width: 100%;
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                "
              >
                <lottie
                  :options="defaultOptions"
                  :height="300"
                  :width="400"
                  v-on:animCreated="handleAnimation"
                />
                <p
                  :class="
                    $vuetify.theme.dark
                      ? 'subtitle-1 primary--text'
                      : 'subtitle-1 black--text'
                  "
                >
                  Cargando Data
                </p>
              </div>
              <v-skeleton-loader
                ref="skeleton"
                type="table-tbody"
                class="mx-auto d-none d-md-block"
              ></v-skeleton-loader>
            </div>
          </template>

          <template v-slot:[`item.active`]="{ item }">
            <v-switch
              @change="
                switchControlChanged(
                  { active: item.active },
                  item['.key'],
                  item
                )
              "
              v-model="item.active"
            ></v-switch>
          </template>

          <template v-slot:[`item.isCategoryShown`]="{ item }">
            <v-switch
              @change="
                switchControlChanged(
                  { isCategoryShown: item.isCategoryShown },
                  item['.key'],
                  item
                )
              "
              v-model="item.isCategoryShown"
            ></v-switch>
          </template>

          <template v-slot:[`item.createdAt`]="{ item }">
            {{ item.createdAt | filterDate }}
          </template>

          <template v-slot:[`item.dateStart`]="{ item }">
            {{ item.dateStart | filterDate }}
          </template>

          <template v-slot:[`item.amount`]="{ item }">
            {{ item.isFixed ? `${currency(item.amount)}` : `${item.amount} %` }}
          </template>

          <template v-slot:[`item.dateEnd`]="{ item }">
            {{ item.dateEnd | filterDate }}
          </template>

          <template v-slot:[`item.options`]="{ item }">
            <v-btn @click="editPromo(item)" small color="primary">Editar</v-btn>
            <v-btn
              @click="deleteCity(item)"
              small
              class="ml-3 white--text"
              color="red darken-4"
              >Eliminar</v-btn
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog
      v-if="!loading"
      persistent
      v-model="modalNewPromo"
      max-width="900px"
    >
      <new-edit-promo
        :key="randomKey"
        :promoToEdit="itemToEdit"
        :type="type"
        :position="promos.length"
        @cancel="modalNewPromo = false"
        @success="handleCreate"
      ></new-edit-promo>
    </v-dialog>

    <!-- modal delete city -->
    <v-dialog max-width="350" v-model="modalDelete" v-if="itemToDelete">
      <v-card>
        <v-card-title class="headline"
          >Confirmar acción
          <v-btn
            style="right: 10px; top: 10px"
            icon
            color="primary"
            fab
            absolute
            @click="modalDelete = false"
          >
            <v-icon dark>fa-times</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          ¿Está seguro que desea eliminar
          <span class="font-weight-bold">{{ itemToDelete.name }}</span
          >?
        </v-card-text>

        <v-card-actions style="justify-content: center">
          <v-btn
            color="red darken-4"
            width="90%"
            style="border-radius: 50px"
            class="mb-3"
            dark
            @click="confirmDelete"
          >
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { db, fb } from "@/firebase";
import Lottie from "../../../components/Lottie";
import * as animationData from "../../../assets/ochoColor.json";
// import { Parser } from "json2csv";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import { mapState } from "vuex";

import newEditPromo from "./new-edit-promo";
// import editAd from './edit-ad'

export default {
  name: "promos",
  components: {
    lottie: Lottie,
    // newAd,
    // editAd
    newEditPromo,
  },
  data() {
    return {
      loading: true,
      saving: false,
      snackbar: false,
      modalEditBusinesses: false,
      snackbarText: "",
      itemToEdit: {},
      itemToDelete: null,
      modalDelete: false,
      citySelected: null,
      promoSelected: null,
      modalEditAd: false,
      type: null,
      promos: [],
      randomKey: 0,
      defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },
      modalNewPromo: false,
      anim: null,
      animationSpeed: 1.1,
      roles: ["sudo", "admin", "EFCM", "AC"],
      headers: [
        {
          text: "Nombre",
          value: "name",
        },
        {
          text: "Descripción",
          value: "description",
        },
        {
          text: "Activo",
          value: "active",
        },
        {
          text: "Mostrar en las categorías",
          value: "isCategoryShown",
        },
        {
          text: "Descuento",
          value: "amount",
        },
        {
          text: "Veces que se ha visto",
          value: "counter",
        },
        {
          text: "Veces que se ha utilizado",
          value: "redeemed",
        },
        {
          text: "Total de usos permitidos",
          value: "maximum",
        },
        {
          text: "Creada",
          value: "createdAt",
        },
        {
          text: "Fecha inicio",
          value: "dateStart",
        },
        {
          text: "Fecha final",
          value: "dateEnd",
        },

        {
          value: "options",
          align: "end",
          width: "200px",
        },
      ],
    };
  },

  filters: {
    filterDate(timestamp) {
      if (!timestamp) return "no disponible";
      return moment(timestamp.toDate()).tz("America/Tegucigalpa").format("LL");
    },
  },

  computed: {
    ...mapState(["user"]),
  },
  methods: {
    currency(value) {
      if (typeof value !== "number") {
        return value;
      }

      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "HNL",
        minimumFractionDigits: 2,
      });

      return formatter.format(value);
    },

    handleAnimation(anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },

    deleteCity(item) {
      this.itemToDelete = item;
      this.modalDelete = true;
    },

    handleCreate() {
      this.modalNewPromo = false;
      this.snackbarText = `Promoción ${
        this.type == "edit" ? "actualizada" : "creada"
      } exitosamente.`;
      this.snackbar = true;
    },

    editPromo(item) {
      this.randomKey = Math.random();
      this.itemToEdit = item;
      this.type = "edit";
      this.modalNewPromo = true;
    },

    newPromo() {
      this.randomKey = Math.random();
      this.modalNewPromo = true;
      this.itemToEdit = null;
      this.type = "new";
    },

    handleUpdate() {
      this.snackbarText = "Información actualizada correctamente.";
      this.snackbar = true;
      this.modalEditAd = false;
    },

    switchControlChanged(data, promoId, promo) {
      if (data && promoId) {
        data.modifiedAt = new Date();
        data.modifiedBy = this.$store.state.user[".key"];
        this.saving = true;

        db.collection("promos")
          .doc(promoId)
          .update(data)
          .then((ref) => {
            this.saving = false;
            this.snackbarText = "Información actualizada correctamente.";
            this.snackbar = true;
          })
          .catch((err) => {
            this.saving = false;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;

            if (promo.hasOwnProperty("active")) promo.active = !promo.active;
            else if (promo.hasOwnProperty("isCategoryShown"))
              promo.isCategoryShown = !promo.isCategoryShown;
          });
      }
    },

    confirmDelete() {
      if (this.itemToDelete) {
        db.collection("promos")
          .doc(this.itemToDelete[".key"])
          .update({
            deleted: true,
            active: false,
            deletedAt: new Date(),
            deletedBy: this.$store.state.user[".key"],
          })
          .then(() => {
            this.modalDelete = false;
            this.snackbar = true;
            this.snackbarText = "Promoción eliminada";
          })
          .catch((error) => {
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
          });
      }
    },
  },

  mounted() {
    this.$store.commit("setSearchTerm", "promoción");
    this.$store.commit("setSearch", "");
    this.$store.state.visibleSearch = true;

    let query;

    if (this.roles.includes(this.user.type)) {
      query = db.collection("promos").where("deleted", "==", false);
    } else {
      query = db
        .collection("promos")
        .where("deleted", "==", false)
        .where("createdBy", "==", this.user[".key"]);
    }

    this.$binding("promos", query).then((promos) => {
      this.loading = false;
    });
  },
};
</script>